@import '~antd/dist/antd.css';

@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@200;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&family=Quicksand:wght@300;400;500&display=swap');

@media (max-width: 600px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 960px) and (max-width 1279px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1280px) {
  html {
    font-size: 16px;
  }
}

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-selection{
  background: #768bf9;
}
::selection{
  background: #768bf9;
}

a {
  color: #768bf9;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

a:hover {
  color: #ffba08;
}
